import {
  ComponentProps,
  CSSProperties,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { Icon } from '@/components/icon';
import { useSentryMonitoring } from '@/hooks/monitoring/use-sentry-monitoring';
import { localeLabels, supportedLocales } from '@/i18n/constants';
import { Locale } from '@/i18n/types';

interface Props {
  locale: Locale;
  hasDropdownIcon?: boolean;
}
export const LocaleSelectorPlaceholder = (props: Props) => {
  const { locale, hasDropdownIcon } = props;

  const { sentryLog } = useSentryMonitoring();

  const [isLoadingFlag, setIsLoadingFlag] = useState<boolean>(true);

  /**
   * NOTE(sontruong):
   * Icons source: https://www.figma.com/design/RimBzcuzFBi9xhXotSeINt/%F0%9F%93%96-User-Components?m=auto&node-id=8149-21146&t=4MSz5fqbGle8gnhg-1
   */
  const localeFlagImageSource = useMemo(() => {
    if (supportedLocales.includes(locale)) {
      return `/assets/icon/locales/${locale}.svg`;
    }
    return '';
  }, [locale]);

  const onLoadFlag = useCallback(() => {
    setIsLoadingFlag(false);
  }, []);

  const onErrorFlag = useCallback<
    NonNullable<ComponentProps<'img'>['onError']>
  >(
    (event) => {
      sentryLog.error(
        new Error(
          `Can not load flag in the LocaleSelectorPlaceholder component - ${locale}`,
          { cause: event },
        ),
      );
    },
    [locale, sentryLog],
  );

  return (
    <>
      <div
        data-animation={isLoadingFlag}
        style={
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          {
            '--locale-flag-height': '18px',
            '--locale-flag-border-radius': '2px',
          } as CSSProperties
        }
        className="relative h-[var(--locale-flag-height)] w-6 rounded-[var(--locale-flag-border-radius)] bg-neutral-300 data-[animation=true]:animate-pulse"
      >
        {localeFlagImageSource ? (
          <img
            data-opacity-0={isLoadingFlag}
            className="absolute bottom-0 left-0 right-0 top-0 rounded-[var(--locale-flag-border-radius)] data-[opacity-0=false]:opacity-100 data-[opacity-0=true]:opacity-0"
            src={localeFlagImageSource}
            alt=""
            onLoad={onLoadFlag}
            onError={onErrorFlag}
          />
        ) : null}
      </div>

      <span>{localeLabels[locale]}</span>

      {hasDropdownIcon ? (
        <Icon name="chevron-down" className="h-4 w-4 text-neutral-500" />
      ) : null}
    </>
  );
};
