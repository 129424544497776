'use client';

import { useHermes } from '@/auth/use-hermes';
import { useIsAppEnvProduction } from '@/store/store';
import * as amplitude from '@amplitude/analytics-browser';
import { useCallback } from 'react';

export interface AmplitudeCustomEventProperties {
  redemptionStep?: string;
  source?: string;
}

export interface AmplitudeTrackEventFunction {
  (eventName: string, eventProperties?: AmplitudeCustomEventProperties): void;
}

export const useTrackAmplitudeEvent = (): AmplitudeTrackEventFunction => {
  const { isImpersonated } = useHermes();
  const isProduction = useIsAppEnvProduction();

  const trackAmplitudeEvent = useCallback<AmplitudeTrackEventFunction>(
    (eventName, eventProperties) => {
      // Do not track events if the user is impersonated in production
      // but allow tracking in non-production environments to collect data
      if (isProduction && isImpersonated) {
        return;
      }

      amplitude.track(eventName, eventProperties);
    },
    [isImpersonated, isProduction],
  );
  return trackAmplitudeEvent;
};
