import { useCallback, useMemo } from 'react';

import DsSelect from '@/design-system-components/ds-select/select';
import { useCurrencyList } from '@/hooks/currency-selector/use-currency-list';
import { useCurrencyMutation } from '@/hooks/currency-selector/use-currency-mutation';
import { useSentryMonitoring } from '@/hooks/monitoring/use-sentry-monitoring';
import { useIsPublicPage } from '@/utils/page-url';

import { defaultCurrency } from './constants';
import { CurrencySelectorItem } from './currency-selector-item';
import { CurrencySelectorTrigger } from './currency-selector-trigger';
import { Currency } from './types';

interface Props {
  isInFooter?: boolean;
}
export const CurrencySelector = (props: Props) => {
  const { isInFooter } = props;

  const { sentryLog } = useSentryMonitoring();
  const isPublicPage = useIsPublicPage();
  const currencyList = useCurrencyList();
  const currencyMutation = useCurrencyMutation();

  const selectedCurrency = useMemo(() => {
    /**
     * NOTE(sontruong):
     * Use 'USD' as the default currency for now.
     * currencyList[0] is 'USD' and it will always exist.
     */
    return (
      currencyList.find((currency) => currency.value === defaultCurrency) ??
      currencyList[0]
    );
  }, [currencyList]);

  const handleChangeCurrency = useCallback(
    async (newCurrency: Currency) => {
      if (!isPublicPage) {
        await currencyMutation.mutateAsync(newCurrency).catch((error) => {
          sentryLog.error(
            new Error(
              `Error while updating user preferred currency - ${error.toString()}`,
              { cause: error },
            ),
          );
        });
      }
    },
    [currencyMutation, isPublicPage, sentryLog],
  );

  return (
    <DsSelect
      onSelectionChange={(key) => {
        if (key) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          handleChangeCurrency(key as Currency);
        }
      }}
      selectedKey={defaultCurrency}
      listBoxClassName="p-0"
      placeholderClassName="data-[placeholder=true]:text-neutral-500"
      popOverClassName="p-0 w-auto rounded"
      triggerClassName="min-w-0 border-none p-0"
      usePopoverOnMobile
      triggerField={() => (
        <CurrencySelectorTrigger
          currency={selectedCurrency}
          hasDropdownIcon={isInFooter ? false : true}
        />
      )}
    >
      {currencyList.map((currency) => {
        return (
          <CurrencySelectorItem key={currency.value} currency={currency} />
        );
      })}
    </DsSelect>
  );
};
