import { useLocalStorageState } from 'ahooks';
import { useCallback } from 'react';

import DsSelect from '@/design-system-components/ds-select/select';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import {
  symbolCookieL10n,
  symbolStoragePreferredLocale,
} from '@/i18n/constants';
import { useLocaleConfig } from '@/i18n/hooks/use-locale-config/use-locale-config';
import { usePathname, useRouter, useSearchParams } from '@/i18n/navigation';
import { Locale } from '@/i18n/types';
import { useIsPublicPage } from '@/utils/page-url';

import { LocaleSelectorItem } from './locale-selector-item';
import { LocaleSelectorTrigger } from './locale-selector-trigger';

interface LocaleSelectorProps {
  isInFooter?: boolean;
}
export const LocaleSelector = (props: LocaleSelectorProps) => {
  const { isInFooter } = props;

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isPublicPage = useIsPublicPage();
  const isI18nFlagEnabled = useFlagsReady('rc_fe_i18n');
  const { locale, locales } = useLocaleConfig();
  const [, setPreferredLocale] = useLocalStorageState<Locale | undefined>(
    symbolStoragePreferredLocale,
  );

  const isI18nEnabled =
    (globalThis.document?.cookie &&
      new RegExp(`${symbolCookieL10n}=1`).test(globalThis.document.cookie)) ||
    isI18nFlagEnabled;

  const handleChangeLocale = useCallback(
    (newLocale: Locale) => {
      if (!isPublicPage) {
        setPreferredLocale(newLocale);
      }

      /**
       * NOTE(sontruong):
       * We should use router.replace() here instead of router.push() when a user changes his locale.
       * router.replace() will REPLACE the current route in the browser history and prevent the user from going back to the old locale.
       * e.g. If he goes to a new page and then goes back to the previous page, he should see the previous page with new locale.
       * Reference: https://nextjs.org/docs/pages/api-reference/functions/use-router#routerreplace
       */
      router?.replace(
        /** FIXME(rongsen/sontruong):
         * @ts-expect-error -- TypeScript will validate that only known `params`
         * are used in combination with a given `pathname`. Since the two will
         * always match for the current route, we can skip runtime checks.
         * { pathname, params, search: searchParams.toString() },
         */
        `${pathname}${
          searchParams.toString() ? `?${searchParams.toString()}` : ''
        }`,
        {
          locale: newLocale,
        },
      );
    },
    [isPublicPage, pathname, router, searchParams, setPreferredLocale],
  );

  return (
    <DsSelect
      onSelectionChange={(key) => {
        if (key) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          handleChangeLocale(key as Locale);
        }
      }}
      selectedKey={locale}
      listBoxClassName="p-0"
      placeholderClassName="data-[placeholder=true]:text-neutral-500"
      popOverClassName="p-0 w-auto rounded"
      triggerClassName="min-w-0 border-none p-0"
      usePopoverOnMobile
      triggerField={() => (
        <LocaleSelectorTrigger
          locale={locale}
          isDisabled={!isI18nEnabled}
          hasDropdownIcon={isInFooter ? false : true}
        />
      )}
    >
      {locales.map((locale) => {
        return <LocaleSelectorItem key={locale} locale={locale} />;
      })}
    </DsSelect>
    /**
     * NOTE(sontruong):
     * We can use the component below if the design changes to use the bottom drawer ON MOBILE VIEWS.
     *
     * <DialogTrigger>
     *   <LocaleSelectorTrigger locale={locale} isDisabled={!isI18nEnabled} hasDropdownIcon={true} />
     *   <Drawer>
     *     {({ close: closeDrawer }) => {
     *       return (
     *         <>
     *           <DrawerHeader />
     *           <DrawerBody className="pb-6">
     *             <ListBox
     *               selectionMode="single"
     *               selectedKeys={[locale]}
     *               className="flex flex-col"
     *               onSelectionChange={(keys) => {
     *                 if (keys) {
     *                   // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
     *                   const newLocale = [...keys][0] as Locale;
     *                   handleChangeLocale(newLocale);
     *                   closeDrawer();
     *                 }
     *               }}
     *             >
     *               {locales.map((locale) => {
     *                 return <LocaleSelectorItem key={locale} locale={locale} />;
     *               })}
     *             </ListBox>
     *           </DrawerBody>
     *         </>
     *       );
     *     }}
     *   </Drawer>
     * </DialogTrigger>
     */
  );
};
