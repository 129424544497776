import { ComponentProps } from 'react';
import { Button } from 'react-aria-components';

import { Locale } from '@/i18n/types';
import { cn } from '@/utils/tailwind';

import { LocaleSelectorPlaceholder } from './locale-selector-placeholder';

interface Props extends ComponentProps<typeof Button> {
  locale: Locale;
  hasDropdownIcon?: boolean;
}
export const LocaleSelectorTrigger = (props: Props) => {
  const { locale, isDisabled, hasDropdownIcon, ...restProps } = props;

  return (
    <Button
      isDisabled={isDisabled}
      className={cn(
        'flex items-center gap-x-3 rounded outline-none',
        'data-[disabled=true]:opacity-20',
        'data-[focus-visible=true]:shadow-focus',
      )}
      {...restProps}
    >
      <LocaleSelectorPlaceholder
        locale={locale}
        hasDropdownIcon={hasDropdownIcon}
      />
    </Button>
  );
};
