import { useTranslations } from 'next-intl';
import { useCallback } from 'react';
import { useNumberFormatter } from 'react-aria';

import { useLoyaltyCurrency } from '@/hooks/bootstrap/bootstrap-hooks';

export function usePointsFormatter() {
  const t = useTranslations('usePointsFormatter');
  const loyaltyCurrency = useLoyaltyCurrency();
  const numberFormatter = useNumberFormatter();

  const pointsText = t('points');

  return useCallback(
    (points: number | undefined, currency?: string) => {
      return points == null
        ? ''
        : `${numberFormatter.format(points)} ${
            currency || loyaltyCurrency?.name || pointsText
          }`;
    },
    [numberFormatter, loyaltyCurrency, pointsText],
  );
}
