import { Icon } from '@/components/icon';
import { useCurrencyList } from '@/hooks/currency-selector/use-currency-list';

interface Props {
  currency: ReturnType<typeof useCurrencyList>[number];
  hasDropdownIcon?: boolean;
}
export const CurrencySelectorPlaceholder = (props: Props) => {
  const { currency, hasDropdownIcon } = props;

  return (
    <>
      <strong>{currency.symbol}</strong>
      <span>{currency.value}</span>
      {hasDropdownIcon ? (
        <Icon name="chevron-down" className="ml-1 h-4 w-4 text-neutral-500" />
      ) : null}
    </>
  );
};
