'use client';

import { useTranslations } from 'next-intl';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useHermes } from '@/auth/use-hermes';
import { Avatar, getAvatarNameFromUser } from '@/components/avatar';
import { CurrencySelector } from '@/components/currency-selector/currency-selector';
import { Icon } from '@/components/icon';
import { LocaleSelector } from '@/components/locale-selector/locale-selector';
import { MenuItemType, isMenuItem } from '@/config-schema/navigation';
import { Button } from '@/design-system-components/button/button';
import {
  CarouselContent,
  CarouselContext,
  CarouselItem,
} from '@/design-system-components/shared-carousel/carousel';
import { useTrackPointsTransferNavigation } from '@/hooks/amplitude/points-transfer/use-track-points-transfer-navigation';
import { useTrackTravelNavigation } from '@/hooks/amplitude/travel/use-track-travel-navigation';
import { useCarousel } from '@/hooks/carousel/use-carousel';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { cn } from '@/utils/tailwind';

import { MenuItemsPageType } from './mobile-header-v2';
import { MenuItem, NavigationMenuItemGtmProps } from './navigation-menu-item';
import { customNavigation } from './utils';

/**
 * This components supports rendering multiple pages of menu items in a carousel.
 *
 * @param {MenuItemsPageType} menuItemsPage - The first menu items page to render.
 * @param {() => void} onClickMenuItem - The callback to call when a menu item is clicked.
 * @returns {React.ReactNode} The returned react component.
 */
export function MobileSideNavigation({
  menuItemsPage,
  onClickMenuItem,
}: {
  menuItemsPage: MenuItemsPageType;
  onClickMenuItem?: () => void;
}) {
  const { logout, loggedIn, user } = useHermes();
  const t = useTranslations('mobileSideNavigation');
  const { setShowRedirectModal } = useContext(RedirectModalContext);
  const { data: pointsAccount } = usePointsAccounts();
  const pointsFormatter = usePointsFormatter();

  const logoutText = t('logout');
  const closeMenuText = t('closeMenu');

  const avatarName = useMemo(() => getAvatarNameFromUser(user), [user]);
  const fullName = `${user?.firstName} ${user?.lastName}`;

  const [menuItemsPages, setMenuItemsPages] = useState<MenuItemsPageType[]>([
    menuItemsPage,
  ]);
  const [navigationIndex, setNavigationIndex] = useState(1);
  const [nextAction, setNextAction] = useState<'next' | 'none'>('none');

  const { emblaRef, emblaApi, onNextButtonClick, onPrevButtonClick, ...props } =
    useCarousel({
      watchDrag: false,
      duration: 12,
    });

  useEffect(() => {
    if (emblaApi && nextAction === 'next' && emblaApi.canScrollNext()) {
      onNextButtonClick();
      setNextAction('none');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextAction, emblaApi?.canScrollNext()]);
  const trackPointsTransferNavigation = useTrackPointsTransferNavigation();
  const trackTravelNavigation = useTrackTravelNavigation();

  return (
    <CarouselContext.Provider
      value={{
        onNextButtonClick,
        onPrevButtonClick,
        carouselRef: emblaRef,
        api: emblaApi,
        ...props,
      }}
    >
      <div
        className="relative h-full w-full text-sm"
        role="region"
        aria-roledescription="carousel"
      >
        <CarouselContent viewableClassName="h-full" className="h-full">
          {menuItemsPages.map((menuItems, listId) => {
            const isFirstPage = listId === 0;

            return (
              <CarouselItem
                key={listId}
                className="relative flex h-full w-full shrink-0 flex-col overflow-y-auto scrollbar-none"
              >
                <button
                  onClick={onClickMenuItem}
                  className="absolute right-0 z-10 flex h-6 w-6 items-center justify-center"
                  aria-label={closeMenuText}
                >
                  <Icon name="xmark" className="h-5 w-5 text-neutral-900" />
                </button>

                {!!user ? (
                  <div
                    className={cn('flex items-center gap-4 pb-6', {
                      hidden: !isFirstPage,
                    })}
                  >
                    <Avatar
                      name={avatarName}
                      picture={user.picture}
                      variant="secondary"
                      className="text-sm font-bold"
                    />
                    <div className="flex flex-col">
                      <div className="pr-6 text-xl font-bold !text-neutral-900">
                        {fullName}
                      </div>
                      <div className="flex gap-2 !text-neutral-900">
                        <Icon
                          name="gem"
                          className="mt-1 h-4 w-4 shrink-0 self-start"
                        />
                        {pointsFormatter(pointsAccount?.pointsBalance)}
                      </div>
                    </div>
                  </div>
                ) : null}

                <BackButtonAndTitle
                  onClick={() => {
                    onPrevButtonClick();
                    setNavigationIndex((prev) => prev - 1);
                  }}
                  title={menuItems.groupName}
                  className={cn({ hidden: isFirstPage })}
                />

                {menuItems.items.map((item) =>
                  isMenuItem(item) ? (
                    <MenuItem
                      href={item.href}
                      key={item.itemType}
                      menuItem={item}
                      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                        customNavigation(
                          item,
                          event,
                          setShowRedirectModal,
                          onClickMenuItem,
                        );
                        trackPointsTransferNavigation(item.itemType, 'top_nav');
                        trackTravelNavigation(item.itemType, 'top_nav');
                      }}
                      dataGtm={JSON.stringify({
                        event: 'homepage_v2_navigation_menu_click',
                        group_0: (item as MenuItemType).groupType,
                        type_0: (item as MenuItemType).itemType,
                      } satisfies NavigationMenuItemGtmProps)}
                      displayMode="simple-with-icon"
                      className="gtm:homepage_v2_navigation_menu_item flex-row items-center gap-3 px-0 py-4 text-neutral-900"
                      iconClassName="fill-neutral-900"
                    />
                  ) : (
                    <MenuItemsPageButton
                      item={item}
                      key={item.groupName}
                      className={cn('py-4 font-normal', {
                        'mt-auto': item.groupType === 'needHelp',
                      })}
                      onClick={(menuItemsPage: MenuItemsPageType) => {
                        setMenuItemsPages([
                          ...menuItemsPages.slice(0, navigationIndex),
                          menuItemsPage,
                        ]);
                        setNavigationIndex((prev) => prev + 1);
                        setNextAction('next');
                      }}
                    />
                  ),
                )}

                <div
                  data-hidden={!isFirstPage}
                  className="flex-row items-center justify-between gap-4 rounded bg-neutral-200 p-4 data-[hidden=false]:flex data-[hidden=true]:hidden"
                >
                  <LocaleSelector />
                  <CurrencySelector />
                </div>

                {loggedIn ? (
                  <Button
                    variant="primary"
                    fullWidth="always"
                    textCenter
                    size="md"
                    className={cn('mt-4', {
                      hidden: !isFirstPage,
                    })}
                    onPress={() => {
                      logout?.();
                    }}
                  >
                    {logoutText}
                  </Button>
                ) : null}
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </div>
    </CarouselContext.Provider>
  );
}

interface BackButtonAndTitleProps {
  onClick: () => void;
  title?: string;
  className?: string;
}

function BackButtonAndTitle({
  onClick,
  title,
  className,
}: BackButtonAndTitleProps) {
  return (
    <div className={cn('relative w-full pb-4 !text-neutral-900', className)}>
      <button onClick={onClick} className="absolute">
        <Icon name="chevron-left" className="left-0 h-4 w-4" />
      </button>
      <div className="mx-auto w-fit uppercase">{title}</div>
    </div>
  );
}

interface NavigationLinkProps {
  item: MenuItemsPageType;
  onClick: (navigationItems: MenuItemsPageType) => void;
  className?: string;
}

function MenuItemsPageButton({
  item,
  onClick,
  className,
}: NavigationLinkProps) {
  return (
    <button
      onClick={() => onClick(item)}
      className={cn('flex flex-row items-center justify-between', className)}
    >
      <div className="flex flex-row items-center gap-3 !text-neutral-900">
        {item.icon ? <Icon name={item.icon} className="h-4 w-4" /> : null}
        <div>{item.groupName}</div>
      </div>

      <Icon name="chevron-right" className="h-4 w-4 text-neutral-900" />
    </button>
  );
}
