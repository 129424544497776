import { ComponentProps } from 'react';
import { Button } from 'react-aria-components';

import { useCurrencyList } from '@/hooks/currency-selector/use-currency-list';
import { cn } from '@/utils/tailwind';

import { CurrencySelectorPlaceholder } from './currency-selector-placeholder';

interface Props extends ComponentProps<typeof Button> {
  currency: ReturnType<typeof useCurrencyList>[number];
  hasDropdownIcon?: boolean;
}
export const CurrencySelectorTrigger = (props: Props) => {
  const { currency, isDisabled, hasDropdownIcon, ...restProps } = props;

  return (
    <Button
      isDisabled={isDisabled}
      className={cn(
        'flex items-center gap-x-2 rounded outline-none',
        'data-[disabled=true]:opacity-20',
        'data-[focus-visible=true]:shadow-focus',
      )}
      {...restProps}
    >
      <CurrencySelectorPlaceholder
        currency={currency}
        hasDropdownIcon={hasDropdownIcon}
      />
    </Button>
  );
};
