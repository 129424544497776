import { MenuNameType } from '@/config-schema/navigation';
import { useCallback } from 'react';
import { useTrackAmplitudeEvent } from '../use-track-amplitude-event';

export const useTrackPointsTransferNavigation = () => {
  const trackAmplitudeEvent = useTrackAmplitudeEvent();
  return useCallback(
    (itemType: MenuNameType, source: 'top_nav' | 'quick_link' | 'footer') => {
      if (itemType !== 'pointsTransfer') {
        return;
      }
      trackAmplitudeEvent('Points Transfer Redeemed', {
        redemptionStep: 'points_transfer_click',
        source,
      });
    },
    [trackAmplitudeEvent],
  );
};
